.webdev-body {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.webdev-hero {
  position: relative;
  /* background-image: url(); */
  width: 100%;
  height: 50vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(7, 240, 221);
  text-align: center;
  padding: 20px;
  background-color: black;
}

.webdev-hero-content {
  max-width: 800px;
  z-index: 1;
}

.webdev-hero-title {
  font-size: 48px;
  margin-bottom: 20px;
  font-weight: 900;
   
    background: linear-gradient(to right,  white, red, rgb(217, 255, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  
}

.webdev-hero-description {
  font-size: 24px;
  line-height: 1.5;
  color: rgb(255, 255, 255);
}

.webdev-service-content, .webdev-service-content-three {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  width: 85%;
  height: 600px;
  margin: 0 auto;
}

.webdev-service-content-two, .webdev-service-content-four {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  height: 500px;
  background-color: #dcdbdb;
}

.webdev-left-content {
  padding: 10px;
  width: 500px;
  text-align: center;
}

.webdev-right-content {
  padding: 10px;
  width: 700px;
}

#black-title {
  color: black;
}

.webdev-title {
  font-size: 52px;
  color: #f6f3f3; 
}

.webdev-description {
  font-size: 20px;
  line-height: 22px;
  color: #fffff5;
}

.webdev-img {
  width: 50%;
}

.webdev-link {
  font-weight: 700;
  font-size: 16px;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 10px 20px;
  border: 3px solid #333;
  display: inline-block;
  margin: 30px 0 0;
  outline: none;
  text-decoration: none;
}

.webdev-link:hover {
  border-color: #2196F3;
}

.webdev-link:before {
  content: '\2192';
  display: inline-block;
  margin-right: 10px;
  transform: translateX(-200%);
  transition: ease-in 0.4s;
  opacity: 0;
}

.webdev-link:hover::before {
  transform: translateX(0%);
  opacity: 1;
}

.webdev-diagonal {
  position: absolute;
  bottom: -2px;
  width: 100%;
  height: 10vw;
}

@media (max-width: 700px) {
  .reduce-size {
    font-size: 40px;
  }
  .webdev-hero-title {
    font-size: 25px;
  }
  .webdev-hero-description {
    font-size: 16px;
  
  }
  
}
