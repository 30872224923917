@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.home-container {
  width: 75%;
  height: 100%;
  max-width: 1170px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-gap: 60px;

  margin: 0 auto;
  margin-top: 30px;
  padding: 35px 0;
}

.home-contentLeft,
.home-contentRight {
  width: 100%;
}

.home-contentLeft .home-row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.home-contentLeft .home-row .home-imgWrapper {
  width: 100%;
  height: 450px;
  overflow: hidden;
  border-radius: 10px;
 
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.15);
}

.home-contentLeft .home-row .home-imgWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
  transition: 0.3s ease;
}

.home-contentLeft .home-row .home-imgWrapper:hover img {
  transform: scale(1.5);
}

.home-contentLeft .home-row .home-imgWrapper:nth-child(odd) {
  transform: translateY(-20px);
}

.home-contentLeft .home-row .home-imgWrapper:nth-child(even) {
  transform: translateY(20px);
}

.home-contentRight .home-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.home-contentRight .home-content h4 {
  font-size: 40px;
  font-weight: 200;
  color: #d35400;
  line-height: 1;
}

.home-contentRight .home-content h2 {
  font-size: 60px;
  color: #c7e80d;
  line-height:1.2;
  font-weight: 700;
  background: linear-gradient(to right,  rgb(255, 255, 255), rgb(0, 255, 217), rgb(68, 68, 68));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-contentRight .home-content p {
  font-size: 19px;
  color: #ffffff;
  
  line-height: 28px;
  padding-bottom: 10px;
  text-align: justify;
  font-weight: 100;
}

.home-contentRight .home-content a {
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 8px 30px;
  color: #fff;
  background: #d35400;
  border-radius: 8px;
  user-select: none;
  
  text-align: justify;
  
}

.readmore-link:hover{
  box-shadow: 0 2px 4px rgba(242, 240, 240, 0.942);
  color:white;

}

@media(max-width: 768px) {
  .home-contentRight .home-content a {
   
    font-size: 10px;
  
}
  .home-container {
    grid-template-columns: 1fr;
    width: 90%;
   
    padding: 0px;
    margin-top: 20px;
  
  }
  .home-contentLeft .home-row {
    grid-template-columns: repeat(2, 1fr);
  }
  .home-contentLeft .home-row .home-imgWrapper {
    height: 150px;
  }
  .home-contentRight .home-content h4 {
    font-size: 25px;
  }
  .home-contentRight .home-content h2 {
    font-size: 25px;
  }
  .home-contentLeft{
    order: 2;
  }
  .home-contentRight{
    order: 1;
  }
  
}
