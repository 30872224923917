/* PortfolioDetails.css */
.portfolio-details-container {
    width: 70%;
    margin: 0 auto;
    padding: 50px 0;
    position: relative;
  }
  
  .portfolio-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 100px;
  }
  
  .portfolio-details-title-container {
    display: flex;
    align-items: center;
    flex: 1;
    margin-right: 20px;
  }
  
  .portfolio-details-title {
    font-size: 36px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #ffffff;
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
  
  .portfolio-details-video {
    flex: 1;
    max-width: 560px;
    overflow: hidden;
    border-radius: 10px;
    background-color: #000;
  }
  
  .portfolio-details-video iframe {
    width: 100%;
    height: 315px;
    border: none;
    border-radius: 10px;
  }
  
  .portfolio-details-divider {
    border-top: 1px solid #ccc;
    margin: 30px auto;
    width: 100%;
  }
  
  .portfolio-details-description {
    margin: 0;
    line-height: 1.6;
    font-size: 20px;
    color: #ffffff;
  }
  
  .portfolio-details-description h3 {
    text-align: left;
    margin-bottom: 20px;
    color: #0dc219;
  }
  
  .portfolio-details-description p {
    text-align: justify;
    margin-bottom: 20px;
  }
  
  /* Media Queries for Mobile Responsiveness */
  @media (max-width: 768px) {
    .portfolio-details-container {
      width: 90%;
      padding: 30px 0;
    }
  
    .portfolio-details-header {
      flex-direction: column;
      align-items: center;
      margin-top: 100px;
    }
  
    .portfolio-details-title-container {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .portfolio-details-title {
      font-size: 28px;
      text-align: center;
    }
  
    .portfolio-details-video {
      width: 100%;
      max-width: none;
    }
  
    .portfolio-details-video iframe {
      height: 300px;
    }
  
    .portfolio-details-description {
      font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .portfolio-details-title {
      font-size: 24px;
    }
  
    .portfolio-details-description {
      font-size: 16px;
    }
  }
  