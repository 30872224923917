.home5-section {
    width: 80%;
    padding: 100px 10px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

  }
  
  .home5-tools-container {
    flex-basis: 45%;
  }
  
  .home5-tools-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
  
  .home5-tool-box {
    width: 100%;
  
    padding: 50px;
    margin-bottom: 20px;
    border: 1px solid #333333;
    border-radius: 20px;
  }
  
  .home5-tool-image img {
    width: 100%;
    height: auto;
  }
  
  .home5-tools-info {
    flex-basis: 45%;
    text-align: left;
  }
  
  .home5-tools-info h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #1e90ff;
  }

  .home5-tools-info h3 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #ffffff;
  }

  .home5-tools-info p {
   font-size: 18px;
    color: #fffefe;
  }
  
  .home5-brand-vision {
    color: #1e90ff; /* Brand color */
  }
  
  @media only screen and (max-width: 768px) {
    .home5-section {
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
      }
    .home5-section {
      flex-direction: column;
    }
    
    .home5-tools-container,
    .home5-tools-info {
      flex-basis: auto;
    }
    
    .home5-tools-list {
      grid-template-columns: 2fr;
    }
    .home5-tools-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
      }
  }
  