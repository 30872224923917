@media screen and (min-width: 1000px) {
  .container-fluid.blue-bg {
    color: #ffffff;
    height: 100%;
    width: 70%;
    margin: 0 auto;
    
  }
}


.timeline-content{
  color: white;
}
.circle {
  font-weight: bold;
  padding: 15px 20px;
  border-radius: 50%;
  background-color: #ED8D8D;
  color: #000000;
  max-height: 50px;
  z-index: 2;
}

.how-it-works.row {
  display: flex;
}

.how-it-works .col-2 {
  display: inline-flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  position: relative;
}

.how-it-works .col-2::after {
  content: '';
  position: absolute;
  border-left: 3px solid #afd41a;
  z-index: 1;
  height: 100%;
  left: 50%;
  top: 0;
}

.how-it-works .col-2.bottom::after {
  height: 50%;
  top: 50%;
}

.how-it-works .col-2.full::after {
  height: 100%;
  left: calc(50% - 3px);
}

.how-it-works .col-2.top::after {
  height: 50%;
  left: 50%;
  top: 0;
}

.timeline div {
  padding: 0;
  height: 40px;
}

.timeline hr {
  border-top: 3px solid #0ed30e;
  margin: 0;
  top: 17px;
  position: relative;
}

.timeline .col-2 {
  display: flex;
  overflow: hidden;
}

.timeline .corner {
  border: 3px solid #70f808;
  width: 100%;
  position: relative;
  border-radius: 15px;
}

.timeline .corner.top-right {
  left: 50%;
  top: -50%;
}

.timeline .corner.left-bottom {
  left: -50%;
  top: calc(50% - 3px);
}

.timeline .corner.top-left {
  left: -50%;
  top: -50%;
}

.timeline .corner.right-bottom {
  left: 50%;
  top: calc(50% - 3px);
}

.timeline-content h5{
  font-size: 40px;
  font-weight: 100;
  color: aqua;
}
.timeline-content p{
  font-size: 20px;
  font-weight: 100;
}

.case-study-title{
  font-size: 80px;
  font-weight: 200;
  padding: 40px;
  color: #ffee07;
  margin-bottom: 50px;
}


.case-study-image{
  height: 500px;
}

@media only screen and (max-width: 768px) {

  .case-study-title{
    font-size: 60px;
    font-weight: 200;
    padding: 40px;
    margin-bottom: 0px;
    
  }
  .case-study-container{
    display: flex;
   flex-direction: column;
  }
  
  .case-study-image{
    height: 400px;
  }
  
}





