.about-intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 50px;
    padding: 20px;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
}

.gif-containers {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.team-gif {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.about-content-box {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    box-shadow: 8px rgba(197, 32, 32, 0.1);
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    text-align: left;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-container h3 {
    font-size: 2em;
    margin: 0;
}



.about-content-box p {
    font-size: 1.3em;
    line-height: 1.6;
    text-align: center;
}

.about-content-box span {
    font-weight: bold;
}

.highlight {
    color: #FFA500; /* Orange color */
}

@media (max-width: 768px) {

    .about-intro-container{
        width: 90%;
        padding: 0px;
    }
    .about-content-box p {
       
        font-size: 1em;
        line-height: 1.6;
    }
    .about-content-box {
        
        
        border-radius: 0px;
        width: 100%;
        
    }
}