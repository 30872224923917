.about-us3-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 0px 20px;
    width: 80%;
    margin: 0 auto;
    
  }
  
  .about-us3-content {
    width: 50%;
    text-align: left;
    padding: 0 20px;
    z-index: 1;
    opacity: 0;
    transform: translateY(50px);
    animation: slide-in3 1s forwards;
  }
  
  .about-us3-content h2 {
    font-size: 40px;
    margin-bottom: 20px;
    color: #79ff3b; /* Choose your desired text color */
  }
  
  .about-us3-content p {
    font-size: 17px;
    line-height: 1.6;
    text-align: justify;
    color: #ffffff; /* Choose your desired text color */
  }
  
  .lottie-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @keyframes slide-in3 {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .about-us3-container {
      flex-direction: column;
      padding: 0px 0px;
      width: 90%;
    }

  
    .about-us3-content,
    .lottie-container {
      width: 100%;
      padding: 0;
    }
  .about-us3-content h2 {
    font-size: 28px;
  font-weight: 300;
    text-align: center;
}
    .about-us3-content p {
      font-size: 17px;
      line-height: 1.6;
      color: #ffffff; /* Choose your desired text color */
      font-weight: 100;
      text-transform: none;
    }
  }
  