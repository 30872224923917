.scroll-to-top {
    position: fixed;
    bottom: 10px;
    right: 20px;
    background-color: #1df3f7;    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.4s, transform 0.2s; /* Adding transition for transform */
    z-index: 1000;
  }
  
  .scroll-to-top.show {
    opacity: 1;
  }
  
  .scroll-to-top:hover {
    transform: scale(1.1); /* Scale up the button on hover */
  }
  
  .scroll-to-top{
    font-size: 20px;
  }

  
  .up-icon{
    color: white;
    font-weight: 100;
    font-size: 23px;
   

  }


  .scroll-to-top:hover{
    background-color: #06eb1d;
    color: wheat;
  }

  .scroll-to-top:hover .up-icon{
    color: #ece4e4;
    transform: translateY(-5px);
  }

 