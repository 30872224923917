/* MediaPromotion.css */

.media-promotion-container {
  width: 100%;
  margin: 0 auto;
  background-color: white;
}

.media-promotion-hero {
  position: relative;
  width: 100%;
  height: 650px; /* Adjust height as needed */
  overflow: hidden;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.media-promotion-hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.7); /* Dim the image */
  animation: zoomInOut 5s ease-in-out infinite; /* Infinite zoom animation */
}

.media-promotion-hero-image:hover {
  transform: scale(1.05);
}

.media-promotion-hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.media-promotion-hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  color: white;
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.media-promotion-hero-title {
  font-size: 4rem;
  font-weight: 600;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  hyphens: none;
  color: rgb(226, 234, 7) !important;
}

.media-promotion-description {
  width: 100%;
  margin: 0 auto;
  padding: 50px;

  text-align: left;
}

.media-promotion-section-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  position: relative;
  animation: slideIn 1.5s ease-out;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.media-promotion-description-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: fadeInUp 1.5s ease-out;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.media-promotion-gif {
  width: 40%;
  height: auto;
  border-radius: 10px;
  margin-right: 20px;
}

.media-promotion-text {
  max-width: 60%;
}

.media-promotion-section-description {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #333;
  margin: 0;
  animation: fadeIn 2s ease-in-out;
}

@media (max-width: 768px) {
  .media-promotion-hero {
    position: relative;
    width: 100%;
    height: 500px; /* Adjust height as needed */
    overflow: hidden;
  }
  .media-promotion-description {
    width: 90%;
    margin: 0 auto;
    padding: 10px;
    text-align: justify;
  
  }
  .media-promotion-section-description {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #333;
    margin: 0;
    animation: fadeIn 2s ease-in-out;
    text-align: justify;
    hyphens: auto;
  }
  .media-promotion-description-content {
    flex-direction: column;
    text-align: center;
  }

  .media-promotion-gif {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .media-promotion-text {
    max-width: 100%;
  }
  .media-promotion-hero-title{
    font-size: 2rem;
  }
}
