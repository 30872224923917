.project-details-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 100px;
    flex-wrap: wrap; /* Allows elements to wrap on smaller screens */
  }
  
  .project-name {
    flex: 1;
    text-align: left;
  }
  
  .project-name h2 {
    font-size: 3rem;
    margin: 0;
    color: white;
  }
  
  .project-slideshow {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 80%;
    max-width: 500px; /* Set the maximum width of the slideshow box */
    height: 300px; /* Set the fixed height of the slideshow box */
  }
  
  .slideshow-image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover; /* Ensures the image covers the box while maintaining its aspect ratio */
    transition: opacity 1s ease-in-out;
  }
  
  hr {
    border: 0;
    height: 1px;
    background: #ccc;
    margin: 20px 0;
  }
  
  .bottom-section {
    display: flex;
    flex-direction: column;
  }
  
  .project-about {
    margin-bottom: 20px;
  }
  
  .project-about h3 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: white;
  }
  
  .project-about p {
    font-size: 1.5rem;
    line-height: 1.8;
    color: white;
    text-align: justify;
  }
  
  .project-images-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .project-images-grid img {
    width: 100%;
    height: 200px; /* Set a fixed height for the images */
    border-radius: 8px;
    object-fit: cover;
  }
  
  /* Media queries for responsiveness */
  
  /* Small devices (landscape phones, 576px and up) */
  @media (max-width: 575.98px) {
    .top-section {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .project-name {
      order: 2; /* Project name below slideshow */
      text-align: center;
    }
  
    .project-name h2 {
      font-size: 2rem;
    }
  
    .project-slideshow {
      order: 1; /* Slideshow above project name */
      width: 100%;
      height: 300px; /* Fixed height for smaller screens */
      max-width: none;
    }
  
    .slideshow-image {
      height: 100%; /* Fixed height for smaller screens */
    }
  
    .project-about h3 {
      font-size: 1.5rem;
    }
  
    .project-about p {
      font-size: 1.2rem;
    }
  
    .project-images-grid {
      display: flex;
      flex-direction: column; /* Show grid elements one by one */
      gap: 10px; /* Add space between elements */
    }
  
    .project-images-grid img {
      height: 200px; /* Fixed height for smaller screens */
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (max-width: 767.98px) {
    .project-name {
      order: 2; /* Project name below slideshow */
      text-align: center;
    }
  
    .project-name h2 {
      font-size: 1.5rem;
      padding-top: 20px;
    }
  
    .project-slideshow {
      order: 1; /* Slideshow above project name */
      width: 100%;
      height: 300px; /* Fixed height for smaller screens */
      max-width: none;
    }
  
    .slideshow-image {
      height: 300px; /* Fixed height for smaller screens */
    }
  
    .project-about h3 {
      font-size: 1.2rem;
    }
  
    .project-about p {
      font-size: 1rem;
    }
  
    .project-images-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (max-width: 991.98px) {
 
  
    .project-images-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  