.career-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 20px;
    padding: 30px;
    border-radius: 20px;
    width: 75%;
   
}

.career-content {
    text-align: center;
}

.career-main-heading {
    font-size: 60px;
    font-weight: 200;
    margin-bottom: 20px;
    color: #ffffff;
}

.career-message {
    font-size: 20px;
    line-height: 1.5;
    color: rgb(255, 255, 255);
}

.career-message a {
    color: #d35400;
    text-decoration: none;
}

.career-message a:hover {
    text-decoration: underline;
}

@media (max-width: 576px) {
    .career-container{
        background-color: inherit;
    }
    .career-main-heading {
        font-size: 25px;
    }

    .career-message {
        font-size: 18px;
    }

    .career-container {
        width: 90%; /* Adjust width for smaller screens */
    }
}
