.about-us-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: 0px 20px;
  width: 80%;
  margin: 0 auto;

}

.about-us-content {
  width: 50%;
  text-align: left;
  padding: 0 20px;
  z-index: 1;
  opacity: 0;
  transform: translateY(50px);
  animation: slide-in 1s forwards;
}

.about-us-h2 {
  font-weight: 100;
  font-size: 80px;
  margin-bottom: 20px;
  color: #ffffff; /* Choose your desired text color */
  text-align: center;
}

.about-us-content p {
  font-size: 1.3rem;
  line-height: 1.6;
  text-align: left;
  color: #c4c0c0; /* Choose your desired text color */
  font-weight: 100;
}

.highlight {
  color: #ffffff; /* Choose your desired highlight color */
  font-weight: bold;
}

.gif-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.local-gif {
  max-width: 100%;
  height: auto;
}
.highlight{
  color:#1e90ff;
}
.highlights{
  color: #2ffe00;
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .about-us-container {
      flex-direction: column;
      padding: 0px 0px;
      width: 90%;
  }

  .about-us-content,
  .gif-container {
      width: 100%;
      padding: 0;
  }
  .about-us-h2 {
    font-size: 60px;
   
  }

  .about-us-content p {
    font-size: 1.1rem;
    line-height: 1.8;
   font-weight: 100;
   hyphens: auto;
   color: white;
   
   text-transform: none;
  }
  .about-us-content h2 {
   text-align: center;
   font-weight: 200;
   font-size: 2rem;
  }

  .local-gif {
    width: 100%;
    height: auto;
  }
}
