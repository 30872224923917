.Career-hero-container {
    position: relative;
    width: 100%;
}

.Career-image-slider {
    width: 100%;
    height: 90vh;
    overflow: hidden;
    position: relative;
}

.Career-image-slide {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: absolute;
    animation: fade 12s infinite;
    opacity: 0;
}

.Career-image-slide:nth-child(1) {
    animation-delay: 0s;
}

.Career-image-slide:nth-child(2) {
    animation-delay: 3s;
}

.Career-image-slide:nth-child(3) {
    animation-delay: 6s;
}

.Career-image-slide:nth-child(4) {
    animation-delay: 9s;
}


@keyframes fade {
    0% { opacity: 0; }
    5% { opacity: 1; }
    25% { opacity: 1; }
    30% { opacity: 0; }
    100% { opacity: 0; }
}
.Career-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
}

.Career-hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
    color: #fff;
    width: 80%;
}

.Career-hero-content h2 {
    font-size: 4.5rem;
    margin-bottom: 20px;
}

.Career-hero-content p {
    font-size: 1.5rem;
}

#green {
    color: #00ff85;
}
#yellow{
    color: #a8ba0a;
}

/* Media queries for responsiveness */

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  
    
   
}

/* Medium devices (tablets, 768px - 991px) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .Career-hero-content h2 {
        font-size: 2.5rem;
    }
    
    
}

/* Small devices (landscape phones, 576px - 767px) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .Career-hero-content h2 {
        font-size: 2rem;
    }
    
    .Career-hero-content p {
        font-size: 1rem;
    }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .Career-hero-content h2 {
        font-size: 1.8rem;
    }
    
    .Career-hero-content p {
        font-size: 0.9rem;
    }
    
}
