/* PortFolio1Comp.css */
.gallery-container {
   width: 100%;
    margin: 0 auto;
    padding: 2rem;
    
  }
  
  .gallery-title {
    text-align: center;
    color: #08b408;
    font-size: 40px;
    font-weight: 100;
    margin-bottom: 20px;
  }
  
  .gallery-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns grid */
    grid-gap: 2rem;
  }
  
  .gallery-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #333333; /* Green border */
    padding: 10px 0px;
    border-radius: 10px;
  }
  
  .gallery-item iframe {
    width: 100%;
    height: 15em; /* 240px */
    border: none;
    border-radius: 0.3125em; /* 5px */
  }
  
  .portfolio-divider {
    width: 100%; /* Full width of the grid item */
    border-top: 2px solid #34e08e; /* Green divider line */
    margin: 20px 0;
  }
  
  .read-more-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  
  .read-more-texts {
    font-size: 16px;
    font-weight: bold;
    color: white;
    text-decoration: none !important;
    margin-right: 10px;
    padding: 10px;
    text-transform: none;
  }
  
  
  .read-more-icon {
    color: #34e08e;
    transform: rotate(320deg); /* Rotated arrow */
    margin-left: 10px; /* Added margin-left for spacing */
  }
  
  @media (max-width: 768px) { 
    .gallery-container{
      
    }
    .gallery-list {
      grid-template-columns: 1fr; /* One column grid */
    }
  }
  
  @media (max-width: 576px) { /* 36em */
    .gallery-item iframe {
      height: 7em; /* 112px */
    }
  }
  

  .read-more-texts, span ,a{
text-decoration: none;
  }