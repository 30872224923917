

.flex-1::-webkit-scrollbar {
    display: none;
  }
  

  .sticky-bar {
    position: sticky;
    top: 89px;
    width: 100%;
    
  }


  @media screen and (max-width: 786px) {
    .hide-for-mobile{
      display: none;
    }
    .portfolio-active{
      color: #ffff00 !important;
    }

  }

  .portfolio-dropdown{
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
  

  }
  .portfolio-active{
    color: #04ff00 !important;
  }

  @media (min-width: 700px) and (max-width: 1000px) {
    .portfilio-desktop{
      width: 100%;
    }
  }
  
  

  @media (max-width: 700px){
    .portfolio-dropdown{
      width: 100%;
     margin-top: 50px !important;
    
  
    }

  }
  
  
