.main-header {
  width: 100%;
  background-color: #1e1e1e;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 0px;
  border-bottom: 2px solid #343a40;
}

.inner-header {
  width: 80%;
  margin: 0 auto;
}

.header-container {
  width: 100%;
  height: 85px;
}

.logo {
  width: 80px;
  height: 70px;
}

.organization-name {
  font-size: 24px;
  color: white;
  margin-left: 10px;
}

.navbar-nav {
  margin-left: auto;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
  font-size: 18px;
  margin-left: 20px;
  font-weight: 100;
  white-space: nowrap;
}



.navbar-dark .navbar-nav .nav-item.active .nav-link {
  color: #4dff07;
}



.dropdown-menu {
  padding-top: 20px !important;

  background-color: #1e1e1e;
  border: none;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.15);
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  position: absolute; /* Ensure absolute positioning */
  left: 0; /* Ensure the dropdown menu is aligned with the navbar */
  z-index: 1000; /* Ensure the dropdown is above other elements */
  
}

.dropdown-menu .dropdown-item {
  color: white;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  justify-content: center;
  font-weight: 100;
}

.dropdown-menu .dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  color: #d35400;
  background-color: inherit;
}

.main-header .navbar-toggler:focus {
  text-decoration: none !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}



.navbar-dark .navbar-toggler {
border: none !important;
}

.header-left,
.header-right {
  display: flex;
  align-items: center;
}

.header-right {
  margin-right: 20px;
}

/* CSS for opening dropdown on hover */
.navbar-nav .nav-item:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

.navbar-nav .nav-item .dropdown-toggle::after {
  display: none;
}

.navbar-dark .navbar-nav .nav-link.active {
  color: #7fff07;
}

.dropdown-menu .dropdown-item.active {
  color: #7fff07;
  background-color: rgba(0, 0, 0, 0.1);
}

/* Add this CSS to remove border around icons and increase their size */
.header-fatime,
.header-fabar {
  border: none !important;
  font-size: 35px; /* Adjust the size as needed */
  transition: transform 0.8s ease; /* Smooth transition */
  
}

/* Add hover effect for smoother animation */


/* Mobile-specific styling */
@media (max-width: 992px) {
  .navbar-collapse {
    background-color: #1e1e1e;
   
  }
  .inner-header {
    width: 100%;
    margin: 0 auto;
  }

  .navbar-nav {
    flex-direction: column;
    align-items: start;
  }

  .navbar-nav .nav-link {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px 20px;
  }

  .navbar-nav .nav-link:last-child {
    border-bottom: none;
  }

  .navbar-nav .nav-item {
    width: 100%;
  }

  .navbar-nav .nav-item .dropdown-menu {
    text-align: left;
    padding-left: 20px;
  }

  
}




@media (max-width: 700px) {
  .organization-name {
    display: none;
  }
  .header-container {
    width: 100%;
    height: 88px;
    margin: 0 auto;
  }
  .inner-header{
    width: 90%;
  }
 
  
  
}







/* App.css */
.organization-name {

  font-weight:200 !important;
  font-size: 33px; /* Adjust the font size */
  color: transparent; /* Set text color to transparent */
  position: relative; /* Position relative for absolute positioning of pseudo-elements */

  z-index: 1; /* Ensure text appears above other elements */
  
}

/* Animated gradient */
.organization-name::before {
  content: 'Swayambhu Strategies'; /* Your company name */
  position: absolute; /* Position the pseudo-element */
  top: 0; /* Align at the top of the parent element */
  left: 0; /* Align at the left of the parent element */
  width: 100%; /* Cover the entire width */
  height: 100%; /* Cover the entire height */
  background-image: linear-gradient(45deg, #bd1010cb, #fff700, #891c06); /* Gradient colors */
  background-size: 200% 200%; /* Make the gradient larger */
  -webkit-background-clip: text; /* Clip the background to the text */
  background-clip: text; /* Clip the background to the text */
  -webkit-text-fill-color: transparent; /* Hide the text */
  animation: bgscroll 5s linear infinite; /* Apply animation */
}

/* Keyframes for background scroll animation */
@keyframes bgscroll {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}






/* Add this media query for smaller screens */
@media (max-width: 700px) {
  .navbar-collapse {
    background-color: #1e1e1e;
    position: fixed;
    top: 80px;
    left: 0;
    bottom: 0;
    width: 280px; /* Adjust the width as needed */
    z-index: 1001; /* Ensure it's above the navbar */
    transform: translateX(-280px); /* Initially hide off the screen */
    transition: transform 0s ease; /* Smooth transition for transform */
   
  }

  .navbar-collapse.show {
    transform: translateX(0); /* Slide in when expanded */
  }

  .navbar-nav .nav-item {
    width: 100%;
  }

  .navbar-nav .nav-link {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px 20px;
  }

  .navbar-nav .nav-link:last-child {
    border-bottom: none;
  }

  .navbar-nav .nav-item .dropdown-menu {
    text-align: left;
    padding-left: 20px;
    left: auto; /* Reset left position */
    right: 100%; /* Position dropdown menu from left to right */
  }
  .navbar-dark .navbar-nav .nav-link {
   
    font-size: 18px;
    margin-left: 0px;
    font-weight: 100;
}
}


@media (min-width: 700px) and (max-width: 980px) {
  .header-container {
    width: 100%;
    height: 85px;
    padding: 0px 50px !important;
    text-align: center;
  }
  .navbar-collapse {
    background-color: #1e1e1e;
    position: fixed;
    top: 85px;
    left: 0;
    bottom: 0;
    width: 50%; /* Adjust the width as needed */
    z-index: 1001; /* Ensure it's above the navbar */
  }
  .navbar-dark .navbar-nav .nav-link {
   
    font-size: 25px;
    margin-left: 0px;
    font-weight: 100;
  
    
}
.dropdown-menu {
 
}

.dropdown-menu .dropdown-item {
  color: white;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  justify-content: center;
  font-weight: 100;
  font-size: 20px;
  text-align: center;
}

 }

 @media (min-width: 1000px) and (max-width: 1200px) {
  .organization-name {

   display: none;
    
  }


 }
