.what-we-do-container {
    position: relative;
    width: 80%;
    margin: 0 auto;
    display: flex;
    grid-auto-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-template-rows: auto;
    grid-gap: 0 40px;
   
    margin-top: 50px;
    margin-bottom: 50px;
}

/* Media Queries for responsiveness */
@media only screen and (max-width: 1000px) {
    .what-we-do-container {
        width: 90%;
    }
}

@media only screen and (max-width: 1000px) {
    .what-we-do-container {
        flex-direction: column;
        align-items: center;
    }
}

.what-we-do-box {
    position: relative;
    height: 400px;
    margin-left: 2%;
    background: #060c21;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000;
}

.what-we-do-box:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: #fff;
    transform: skew(2deg, 2deg);
    z-index: -1;
}

.what-we-do-box:nth-child(1):before {
    background: linear-gradient(315deg, #ff0057, #e64a19);
}

.what-we-do-box:nth-child(2):before {
    background: linear-gradient(315deg, #89ff00, #00bcd4);
}

.what-we-do-box:nth-child(3):before {
    background: linear-gradient(315deg, #e91e63, #5d02ff);
}

.what-we-do-box:nth-child(4):before {
    background: linear-gradient(315deg, #ff0000, #ffc107);
}

.what-we-do-box:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.05);
    pointer-events: none;
}



.what-we-do-content {
    position: relative;
    padding: 20px;
    transform: translateY(40px);
}

.what-we-do-content h2 {
    position: absolute;
    top: -60px;
    right: 20px;
    margin: 0;
    padding: 0;
    font-size: 10em;
    color: rgba(255, 255, 255, 0.05);
    transition: 0.5s;
}

.what-we-do-box:hover .what-we-do-content h2 {
    top: -140px;
}

.what-we-do-content h3 {
    margin: 0 0 10px;
    padding: 0;
    font-size: 40px;
    font-weight: 500;
    color: #bce604;
}

.what-we-do-content p {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 16px;
}

.what-we-do-content a {
    position: relative;
    margin: 20px 0 0;
    padding: 10px 20px;
    text-decoration: none;
    border: 1px solid #fff;
    display: inline-block;
    color: #fff;
    transition: 0.5s;
    transform: translateY(-40px);
    opacity: 0;
    visibility: hidden;
}

.what-we-do-box:hover .what-we-do-content a {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

.what-we-do-content a:hover {
    color: #000;
    background: #fff;
}
