.services-container {
    width: 80%;
   
    margin: 0 auto;
    margin-bottom: 50px;
}

.title-style {
    text-align: left;
    margin-bottom: 20px;
    color: #34e08e;
    font-size: 1.8rem;
}

.grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 3 columns */
    grid-template-rows: repeat(2, 1fr); /* 3 rows */
    gap: 40px;
}

.grid-item {
    text-align: left;
  
    overflow: hidden;
    text-decoration: none;
}

.grid-item img {
    max-width: 100%;
    border-radius: 20px;
    height: auto;
    display: block;
    transition: transform 0.3s ease; /* Smooth transition */
}

.grid-item:hover img {
    transform: translateY(-5px); /* Move image up by 5px on hover */
}

.project-info {
    padding: 0px;
    text-decoration:none;
}



.project-title {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 1.4rem;
    color: #ffffff;
    text-decoration: none;
}

.project-title h3{
    text-decoration: none;
}
.project-description {
    font-size: 1.2rem;
    color: #777;
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
    .services-container {
        width: 90%;
    }
}

@media screen and (max-width: 992px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr); /* 2 columns */
        gap: 30px;
    }
}

@media screen and (max-width: 576px) {
    .grid-container {
        
    }
    .grid-item img {
      
        border-radius: 10px;
       
    }
    
}
