@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.home9-section {
    position: relative; 
    width: 100%;
    background-image: url('../../assets//b-wave-background.webp');
    background-size: cover;
    background-position: center;
  }
  
  .home9-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; 
    background-color: rgba(0, 0, 0, 5); 

  }
  
.home9-container {
  display: flex;

  width: 80%;
  height: auto;
 
  margin: 0 auto;
  padding: 20px; 
}

.home9-left,
.home9-right {
  width: 100%; 

  padding: 20px 0; 
}

.home9-left h3,
.home9-left h2 {
  font-size: 24px;
  color: #f4f4f4;
  margin-top: 10px;
}
.home9-left h3{
    color: #1e90ff;
    font-size: 40px;
}



.home9-right-content p {
  font-size: 18px; 
  line-height: 1.7; 
  color: rgb(245, 244, 244);
}

@media screen and (min-width: 768px) {
  .home9-container {
    width: 90%; 
  }

  .home9-left,
  .home9-right {
    width: 50%; 
    text-align: left; 
    padding: 50px;
  }


  .home9-left h2 {
    font-size: 30px; 
  }

  .home9-right-content p {
    font-size: 18px; 
    text-align: justify;
   
  }
  

}


@media screen and (max-width: 767px) {
  .home9-section {
  
    background-image: url('');
    background-size: cover;
    background-position: center;
  }
    .home9-container {
      flex-direction: column;
      width: 90%;
      margin-top: 50px;
      padding: 0px; 
    }
  
    .home9-left,
    .home9-right {
      width: 100%; 
      padding: 10px 0; 
    }
    .home9-right-content p {
      font-size: 16px; 
      text-align: justify;
      font-weight: 100;
     
    }
    .home9-left h3 {
  font-size: 25px;
 
}
.home9-left h2 {
  font-size: 18px;
  font-weight: 100;
 
}
.home9-section::before {
 
  background-color: rgba(0, 0, 0, 0); 

}
  }
  