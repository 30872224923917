

.content-creation-container {
    padding: 50px;
    width: 80%;
    margin: 0 auto;
}

.section-title {
    font-size: 4rem;
    font-weight: 100;
    margin-bottom: 20px;
    color: white;
    text-align: center;
}

.section-description {
    font-size: 1.4rem;
    margin-bottom: 40px;
    font-weight: 100;
    color: white;
    text-align: justify;
    text-align: center;
}


.service-item p {
    font-size: 1.2rem;
}

.service-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    border-color: #66fcf1; 
}

.service-icon {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #66fcf1;
}

@media (max-width: 767px) {
    .services-list {
        grid-template-columns: 1fr;
    }
}




.divider {
    width: 300px; 
    height: 2px;
    background-color: #66fcf1; 
    margin: 10px auto; }













.service-boxes {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .service-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    margin: 30px;
    border: 1px solid #66fcf1;
    border-radius: 8px;
    width: 400px;
    height: 400px;
    box-sizing: border-box;
    background-color: rgb(0, 0, 0);
    transition: transform 0.3s, box-shadow 0.3s;
   
  }
  
  .service-box:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(249, 249, 249, 0.3);
  }
  
  .service-icon {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #66fcf1;
  }
  
  .service-title {
    font-size: 1.5rem;
    margin: 10px 0;
    color: white;
    font-weight: 200;
  }
  
  .service-divider {
    width: 50%;
    margin: 10px auto;
    border: none;
    height: 2px;
    background-color: #66fcf1;
  }
  
  .service-description {
    font-size: 1.3rem;
    color: white;
    font-weight: 100;
  }
  
  
  
 
  
  @media (max-width: 767px) {
   
  
    .service-box {
      
        width: 100%;
        height: 100%;
       
       
      }
      .content-creation-container {
        padding: 10px;
        width: 100%;
       
    }
    .section-title {
        font-size: 3rem;
      
    }
    
    .section-description {
        font-size: 1.2rem;
      
        text-align: justify;
        padding: 20px;
    }
    
      
  }
  


  @media (min-width:768px) and (max-width:1270px){
    .services-list {
      grid-template-columns: 2fr;
  }
  .service-box {
   
   
    box-sizing: border-box;
    background-color: rgb(0, 0, 0);
    transition: transform 0.3s, box-shadow 0.3s;
   
  }
  .content-creation-container {
    padding: 10px;
    width: 100%;
    margin: 0 auto;
}

  

  }