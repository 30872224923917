.hero-container {
    position: relative;
    width: 100%;
}

.image-slider {
    width: 100%;
    height: 90vh;
    overflow: hidden;
    position: relative;
}

.image-slide {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: absolute;
    animation: slide 20s linear infinite;
    opacity: 0;
}

.image-slide:first-child {
    opacity: 1;
    animation-delay: 0s;
}

.image-slide:nth-child(2) {
    animation-delay: 5s;
}

.image-slide:nth-child(3) {
    animation-delay: 10s;
}

.image-slide:nth-child(4) {
    animation-delay: 15s;
}

@keyframes slide {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
}

.hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
    color: #fff;
    width: 80%;
}

.hero-content h2 {
    font-size: 4.5rem;
    margin-bottom: 20px;
    hyphens: none;
}

.hero-content p {
    font-size: 1.2rem;
}

#green {
    color: #00ff85;
}

/* Media queries for responsiveness */

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .hero-content h2 {
       
    }
    
    .hero-content p {
        font-size: 1.2rem;
    }
}

/* Medium devices (tablets, 768px - 991px) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .hero-content h2 {
        
    }
    
    .hero-content p {
        font-size: 1.1rem;
    }
}

/* Small devices (landscape phones, 576px - 767px) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .hero-content h2 {
        font-size: 2rem;
    }
    
    .hero-content p {
        font-size: 1rem;
    }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .hero-content h2 {
        font-size: 1.8rem;
    }
    
    .hero-content p {
        font-size: 0.9rem;
    }
    
}
