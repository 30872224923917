.get-started-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    background: url('../assets/getintouch.jpg') no-repeat center center/cover;
  }
  
  .get-started-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }
  
  .get-started-content {
    position: relative;
    text-align: center;
    color: white;
    z-index: 1; 
  }
  
  .get-started-vertical-line {
    width: 2px;
    height: 200px;
    background-color: #66fcf1;
    margin: 0 auto 20px;
  }
  
  .get-started-social-icons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .get-started-icon-circle {
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: 2px solid #66fcf1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    transition: transform 0.3s ease-in-out; 
  }
  
  .get-started-icon-circle:hover {
    transform: translateY(-5px); 
    color: black;
  }
  
  
  
  .get-started-social-icon {
    color: white;
    font-size: 1.5rem;   filter: brightness(1.5); 
    transition: filter 0.3s;}
  
  .get-started-social-icon:hover {
    filter: brightness(2); 
    cursor: pointer;
  }
  
  
  .get-started-text-section {
    margin-top: 20px;
    font-size: 80px;
    font-weight: 200;
    line-height: 1;
  }
  
  .get-started-button {
    border: 2px solid #66fcf1;
    background-color: transparent;
    color: white;
    padding: 10px 20px;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .get-started-button:hover {
    background-color: white;
    color: black;
  }
  
  @media (max-width: 767px) {
    .get-started-vertical-line {
      height: 100px;
    }
  
    .get-started-icon-circle {
      width: 40px;
      height: 40px;
    }
  
    .get-started-social-icon {
      font-size: 1.2rem;
    }
  
    .get-started-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
    .get-started-text-section {
      font-size: 50px;
      
    }
    
  }
  