.content-gallery-container {
    width: 80%;
    margin: 0 auto;
    padding: 2rem;
  }
  .content-gallery-container h2 {
   font-size: 80px;
   text-align: center;
   font-weight: 100;
   color: white;
  margin-bottom: 50px;
  }
  
  .content-gallery-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }
  
  .content-gallery-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #333333;
    padding: 10px 0px;
    border-radius: 10px;
  }
  
  .content-gallery-item iframe {
    width: 100%;
    height: 15em;
    border: none;
    border-radius: 0.3125em;
  }
  
  .content-gallery-divider {
    width: 100%;
    border-top: 2px solid #34e08e;
    margin: 20px 0;
  }
  
  .content-read-more-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  
  .content-read-more-text {
    font-size: 16px;
    font-weight: bold;
    color: white;
    text-decoration: none !important;
    margin-right: 10px;
    padding: 10px;
    text-transform: none;
  }
  
  .content-read-more-icon {
    color: #34e08e;
    transform: rotate(320deg);
    margin-left: 10px;
  }
  
  @media (max-width: 768px) { 
    .content-gallery-container {
        width: 90%;
        margin: 0 auto;
        padding: 1rem;
      }
      .content-gallery-container h2 {
       font-size: 60px;
      
      }
      
    .content-gallery-list {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 576px) {
    .content-gallery-item iframe {
      height: 15em;
    }
  }
  