.maps-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    color: white;
    white-space: nowrap;
}

.details-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.info-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.info-container i {
    margin-right: 10px;
    color: #1e90ff;
    font-size: 25px;
    margin-right: 20px;
}

.maps-frame {
    width: 50%;
    height: auto;
}


/* Responsive styles */
@media (max-width: 1024px) {
    .maps-container {
        padding: 15px;
        flex-direction: column;
    }

    .maps-frame {
        width: 100%;
    }

    .maps-frame iframe {
        height: 400px;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .maps-container {
        padding: 10px;
        width: 90%;
    }

    .maps-frame {
        width: 100%;
    }

    .maps-frame iframe {
        height: 350px;
        width: 100%;
    }
    .less-ft{
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .maps-container {
        padding: 5px;
    }

    .maps-frame {
        width: 100%;
    }

    .maps-frame iframe {
        height: 400px;
        width: 100%;
    }
}
