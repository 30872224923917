.service-intro-container {
    width: 80%;
    padding: 0px 0;
    margin: 30px auto;
    position: relative;
    overflow: hidden;
}

.service-intro-container h1 {
    text-align: center;
    font-weight: 200;
    color: #fbfcfb;
    margin-bottom: 20px;
    font-size: 70px;
}

.service-intro-container p {
    text-align: justify;
    font-size: 1.3rem;
    color: #888;
    line-height: 1.6;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .service-intro-container {
        width: 90%;
        padding: 0px 0;
        margin: 10px auto;
    }

    .service-intro-container h1 {
        font-size: 40px;
        margin-bottom: 15px;
        font-weight: 200;
        margin-top: 15px;
        color: #ffffff;
    }

    .service-intro-container p {
        font-size: 1.2rem;
        text-align: left;
        text-align: justify;
        text-transform: none;
        hyphens: auto;
        font-weight: 100;
        color: white;
    }
}
