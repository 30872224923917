.home-hero-container {
    position: relative;
    width: 100%;
    height: 90vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.home-image-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    animation: slide 9s linear infinite;
}

.home-image-slide.active {
    animation: fade-in-out 3s linear infinite;
}

@keyframes fade {
    0% { opacity: 0; }
    10% { opacity: 1; }
    30% { opacity: 1; }
    40% { opacity: 0; }
    100% { opacity: 0; }
}

.home-image-slide:nth-child(1) {
    animation-delay: 0s;
}
.home-image-slide:nth-child(2) {
    animation-delay: 2s;
}
.home-image-slide:nth-child(3) {
    animation-delay: 4s;
}
.home-image-slide:nth-child(4) {
    animation-delay: 6s;
}
.home-image-slide:nth-child(5) {
    animation-delay: 8s;
}
.home-image-slide:nth-child(6) {
    animation-delay: 10s;
}

.home-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
}

.home-hero-content {
    position: relative;
    text-align: center;
    z-index: 1;
    color: #fff;
    width: 80%;
}

.home-hero-content h2 {
    font-size: 4.5rem;
    margin-bottom: 20px;
}

.home-hero-content p {
    font-size: 1.2rem;
}

#green {
    color: #00ff85;
}

/* Add hover effect to "Get in Touch" button */
.get-in-touch-button {
    display: inline-block;
    padding: 10px 20px;
   border: 1px solid #00ff85;
  
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    font-size: 20px;
    font-weight: 100;
}

.get-in-touch-button:hover {
    background-color: #fff;
    color: #000000;
}


/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .home-hero-content h2 {
        font-size: 4.5rem;
    }

    .home-hero-content p {
        font-size: 1.2rem;
    }
}

/* Medium devices (tablets, 768px - 991px) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .home-hero-content h2 {
        font-size: 3rem;
    }

    .home-hero-content p {
        font-size: 1.1rem;
    }
}

/* Small devices (landscape phones, 576px - 767px) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .home-hero-container {
        height: 70vh; /* Reduce height for smaller screens */
    }

    .home-hero-content h2 {
        font-size: 2.5rem;
    }

    .home-hero-content p {
        font-size: 1rem;
    }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .get-in-touch-button{
        font-size: 10px;
    }
    .home-hero-container {
        height: 40vh;
    }

    .home-hero-content h2 {
        font-size: 1.5rem;
    }

    .home-hero-content p {
        font-size: 0.9rem;
    }
}

@media (min-width: 700px) and (max-width: 1000px) {
    .home-hero-container {
        height: 40vh; 
    }

}
