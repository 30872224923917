.footer-section {
    width: 80%;
    margin: 0 auto;
  }
  
  .footer {
    padding: 40px 20px;
    color: #333;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    color: white;
    background-color: #252525;

  }

  #email:hover{
    text-decoration: underline #1e90ff;
    cursor: pointer;
    color: white;
  }
.footer p{
  font-size: 18px;
  font-weight: 100;
  white-space: nowrap;
}

  .footer h3{
    color:aqua;
  }

  .footer-links a{
    text-decoration: none;
    color: inherit;
  }
  .footer-links a:hover{
    background: linear-gradient(to right,  white, red, rgb(217, 255, 0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  
  .footer-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .footer-company-name{
    font-weight: 900;
   
    background: linear-gradient(to right,  white, red, rgb(217, 255, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  }
  
  .company-info {
    display: flex;
    align-items: center;
  }
  
  .footer-logo {
    width: 100px;
    height: 100px;
    margin-right: 10px;
  }
  
  .company-name {
    font-size: 24px;
    font-weight: bold;
    
  }
  
  .footer-middle {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .footer-middle > div {
    flex: 1;
    margin-right: 20px;
  }
  
  .find-us, .connect, .whatsapp {
    max-width: 200px;
  }
  
  .whatsapp-connect {
    display: flex;
  }
  
  .whatsapp-input {
    flex: 1;
    padding: 5px;
    margin-right: 10px;
    background-color: #232323;
    border: none;
    cursor: pointer;
  }
  
  .whatsapp-send {
    background-color: #ffffff;
    border: none;
    padding: 15px;
    cursor: pointer;
    color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 20px;
    transition: opacity 0.4s, transform 0.2s;

  }
  .whatsapp-send:hover{
    transform: translateX(5px);
    background-color: transparent;
    border: 1px solid rgb(37, 234, 11);
    color: #30e707;
   
  }
  
  .footer-bottom {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999999;
  }
  
  .footer-links {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-links li {
    margin-right: 20px;
  }
  
  .footer-social-media {
    display: flex;
    gap: 10px;
  }
  
  .social-icon {
    font-size: 28px;
    color: white;
    cursor: pointer;
    margin-right: 20px;
  }
  
  .social-icon:hover {
    color: #3498db;
  }
  
  /* Responsive styles */
  @media (max-width: 1000px) {
    .whatsapp-input {
     
      padding: 0px;
      margin-right: 5px;
     
  }
    .footer-section {
      width: 90%;
      margin: 0 auto;
    }
    
    .footer {
      padding: 0px 0px;
      padding-bottom: 20px;
   
    }
    .footer-middle {
      flex-direction: column;
    }
  
    .footer-middle > div {
      margin-bottom: 20px;
      margin-right: 0;
    }
  
    .footer-links {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .footer-links li {
      margin-bottom: 10px;
    }
  
    .footer-bottom {
      flex-direction: column;
      align-items: flex-start;
    }
    .whatsapp-send {
      margin-left: 20px;
    } 
    .whatsapp-input{
      padding: 0px 20px;
    }
  }
  

  

  .whatsapp-input:hover + .whatsapp-send {
    background-color: rgb(0, 255, 26);
    cursor: pointer;
    transform: translateX(5px);
    background-color: transparent;
    border: 1px solid rgb(37, 234, 11);
    color: #30e707;
  }
  

 