.performance-marketing-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 80%;
    margin: 0 auto;
    margin-top: 20px !important;
    
}


.performance-marketing-left {
    width: 45%; /* Adjust width as needed */
    display: flex;
    align-items: center;
}

.performance-marketing-text-container {
    color: #fff; /* Text color */
}

.performance-marketing-about-heading {
    font-size: 80px; /* Adjust font size as needed */
    font-weight: 100;
    border-left: 5px solid rgb(0, 244, 227);
    color: #05f6ea;
    padding-left: 50px;
}

.performance-marketing-about-description {
    font-size: 25px;
    font-weight: 100;
    letter-spacing: 0.7px; /* Experiment with different negative values */
}
.performance-marketing-right {
    width: 45%; /* Adjust width as needed */
}

.performance-marketing-about-image {
    width: 100%;
    height: 500px;
}
.What-we-do-title{
    font-size: 100px;
    font-weight: 200;
  
    color: rgb(5, 232, 236);
    width: 80%;
    margin: 0 auto;
}

@media only screen and (max-width: 768px) {
    .performance-marketing-section {
        width: 90%;
        flex-direction: column;
        align-items: stretch;
        
    }
    
    .performance-marketing-left, .performance-marketing-right {
        width: 100%;
    }
   
    .performance-marketing-about-heading {
        font-size: 60px; /* Adjust font size as needed */
        font-weight: 100;
        border-left: 5px solid white;
        padding-left: 20px;
        
       
    }
    
    .performance-marketing-about-description {
        font-size: 20px;
    text-align: center;
    text-align: left;
    
    }
    .performance-marketing-right {
        width: 100%; /* Adjust width as needed */
    }
    .performance-marketing-left {
        width: 100%; /* Adjust width as needed */
        
    }

    .performance-marketing-about-image {
        width: 100%;
        height:auto;
    }
    .What-we-do-title{
        font-size: 70px;
        font-weight: 100;
      
        color: white;
        width: 80%;
        margin: 0 auto;
    }
}


