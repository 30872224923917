.description-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: white;
    width: 80%;
    margin: 0 auto;
    background-color: black;
}

.description-part {
    flex: 1;
    margin: 10px;
    text-align: center;
}

.description-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    border: 2px solid rgb(5, 253, 245);
   
    
}


.image-title {
    margin-top: 10px;
    font-weight: 800;
    font-size: 25px;
    color: rgb(234, 255, 0);
}

.description-text {
    flex: 2;
    padding: 20px;
    font-size: 25px;
    line-height: 1.5;
    text-align: left;
    font-weight: 100;
}

/* Responsive Design */
@media (max-width: 768px) {
    .description-container {
        flex-direction: column;
        align-items: center;
    }

    .description-part {
        margin: 10px 0;
    }

    .description-text {
        padding: 10px;
        font-size: 18px;
    }
}
