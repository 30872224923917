.about-us2-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 0px 20px;
    width: 80%;
    margin: 0 auto;
    
  }
  
  .about-us2-content {
    width: 50%;
    text-align: left;
    padding: 0 20px;
    z-index: 1;
    opacity: 0;
    transform: translateY(50px);
    animation: slide-in2 1s forwards;
  }
  
  .about-us2-content h2 {
    font-size: 40px;
    margin-bottom: 20px;
    color: #24d910; /* Choose your desired text color */
  }
  
  .about-us2-content p {
    font-size: 17px;
    line-height: 1.6;
    text-align: justify;
    color: #ffffff; /* Choose your desired text color */
  }
  
  .team-gif {
    max-width: 100%;
    height: auto;
  }
  
  .gif2-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  @keyframes slide-in2 {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .about-us2-container {
      flex-direction: column;
      padding: 0px 0px;
      width: 90%;
    }
  
    .about-us2-content,
    .gif2-container {
      width: 100%;
      padding: 0;
    }
    .about-us2-content {
     order: 1;
    }
    .gif2-container {
      order: 2;
    }
    .about-us2-content p {
      font-size: 17px;
      line-height: 1.6;
      font-weight: 100;
      color: #ffffff; /* Choose your desired text color */
    }
    .about-us2-content h2 {
      text-align: center;
      font-weight: 300;
      font-size: 28px;
    }
  
    .team-gif {
      width: 100%;
      height: auto;
    }
    .gif2-container {
      
      
    }
    
  }
  