

.forward-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem 2rem;
  color: #fafafa;
  border-radius: 10px;
}

.forward-title {
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  color: #ffffff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  letter-spacing: 2px;
  text-transform: uppercase;
}

.forward-content {
  font-size: 1.25rem;
  font-weight: 300;
  max-width: 900px;
  margin-bottom: 2.5rem;
  line-height: 1.75;
  color: #e0e0e0;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  padding: 0 1rem;
}

.forward-info {
  font-size: 1.25rem;
  font-weight: 500;
  color: #ffeb3b;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 1rem;
}

/* Responsive styling */
@media (max-width: 850px) {
  .forward-title {
    font-size: 2.5rem;
  }

  .forward-content {
    font-size: 1.1rem;
    padding: 0 1rem;
  }

  .forward-info {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .forward-title {
    font-size: 2rem;
  }

  .forward-content {
    font-size: 1rem;
    padding: 0 0.5rem;
  }

  .forward-info {
    font-size: 1rem;
  }
}

