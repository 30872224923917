.home-about-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
  }
  
  .point-boxes {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .point-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    margin: 10px;
    border: 1px solid #66fcf1;
    border-radius: 8px;
    width: 380px;
    height: 400px;
    box-sizing: border-box;
    background-color: rgb(0, 0, 0);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .point-box:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .point-icon {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #66fcf1;
  }
  
  .point-title {
    font-size: 1.5rem;
    margin: 10px 0;
    color: white;
  }
  
  .point-divider {
    width: 50%;
    margin: 10px auto;
    border: none;
    height: 2px;
    background-color: #66fcf1;
  }
  
  .point-description {
    font-size: 1rem;
    color: white;
  }
  
  .about-text {
    text-align: center;
    margin-bottom: 20px;
    padding: 20px;
  }
  
  .about-text p {
    max-width: 1000px;
    margin: 0 auto;
    font-size: 1.6rem;
    color: white;
    font-weight: 200;
  }
  
  @media (max-width: 767px) {
    .home-about-container {
      flex-direction: column;
    }
  
    .point-box {
      width: 100%;
      max-width: 300px;
      height: auto;
    }
    .about-text p {
        width: auto;
       
        font-size: 1rem;
       
        font-weight: 200;
        text-align: left;
      }
      
  }
  
  @media (min-width: 768px) and (max-width: 1000px) {
   
   
  }
